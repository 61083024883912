<template>
  <div class="health-program-carousel">
    <b-container v-if="isLargeScreen" ref="tilesContainer" class="small-tiles-container">
      <b-row v-for="(row, rowIndex) in programsTable" v-bind:key="row.row">
        <b-col :id="program.ref"
               v-bind:class="[{'highlighted': highlightedRef === program.ref}, 'tile']"
               v-for="(program, colIndex) in row.tiles" v-bind:key="program.ref"
               @click="selectTile(program, rowIndex, colIndex)"
               :ref="program.ref"
               @mouseenter="mouseEnterTile(program.ref)"
               @mouseleave="mouseLeaveTile(program.ref)"
        >
          <div class="small-photo-image-container" :id="program.ref.concat('-image-container')">
            <div class="small-photo-image" :id="program.ref.concat('-image')"></div>
            <div v-if="highlightedRef === program.ref" class="hover-program-name">{{program.name}}</div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="!isLargeScreen" ref="tilesContainer" v-bind:class="[{'inactive': !isLargeScreen && selectedProgram}, 'small-tiles-container', 'small-screen-small-tiles-container']">
      <b-row v-for="(program, rowIndex) in programs" v-bind:key="program.ref">
        <b-col :id="program.ref"
               v-bind:class="[{'highlighted': highlightedRef === program.ref}, 'tile']"
               @click="selectTile(program, rowIndex, 0)"
               :ref="program.ref"
               @mouseenter="mouseEnterTile(program.ref)"
               @mouseleave="mouseLeaveTile(program.ref)"
        >
          <div class="small-photo-image-container" :id="program.ref.concat('-image-container')">
            <div class="small-photo-image" :id="program.ref.concat('-image')"></div>
            <div v-if="highlightedRef === program.ref" class="hover-program-name">{{program.name}}</div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="large-photo-container" ref="largePhotoContainer" v-if="selectedProgram">
      <b-row>
        <b-col class="large-tile-column"
               @click="unselectTile()"
        >
          <div class="large-photo-image-container" ref="largePhotoImageContainer">
            <div class="large-photo-image" ref="largePhotoImage"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="selectedProgram" class="carousel-content" ref="carouselContent">
       Insert Health Program Content Here
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import anime from "animejs";
import Vue from "vue";

export default {
  name: "HealthProgramCarousel",
  data() {
    return {
      isLargeScreen: false,
      highlightedRef: null,
      programs: [
        {
          ref: "tile-marshallFire",
          name: "Marshall Fires",
          description: "Here is information about my health program",
          background: {
            url: "/healthprograms/marshallfire.jpg",
            aspectRatio: 1.5,
            whenSmall: {
              totalWidthFactor: 0.8,
              top: -335,
              left: -140
            },
            whenLarge: {
              totalWidthFactor: 1,
              top: -279,
              left: 0,
              useVerticalScreenFactor: true
            },
            whenSingle: {
              width: 740,
              top: -315,
              left: 0
            }
          }
        },
        {
          ref: "tile-kingSoopersShooting",
          name: "Boulder Mass Shooting",
          description: "Here is information about my health program",
          background: {
            url: "/healthprograms/kingsoopers.jpg",
            aspectRatio: 1.5,
            whenSmall: {
              totalWidthFactor: 0.75,
              top: -72,
              left: -479
            },
            whenLarge: {
              totalWidthFactor: 1,
              top: -214,
              left: 0,
              useVerticalScreenFactor: true
            },
            whenSingle: {
              width: 740,
              top: -88,
              left: 0
            }
          }
        },
        {
          ref: "tile-veterans",
          name: "Veterans",
          description: "Here is information about my health program",
          background: {
            url: "/healthprograms/veterans.jpg",
            aspectRatio: 1.78,
            whenSmall: {
              totalWidthFactor: 0.75,
              top: -72,
              left: -479
            },
            whenLarge: {
              totalWidthFactor: 1,
              top: -10,
              left: 0,
              useVerticalScreenFactor: false
            },
            whenSingle: {
              width: 740,
              top: -62,
              left: 0
            }
          }
        },
        {
          ref: "tile-covid",
          name: "Covid Loss",
          description: "Here is information about my health program",
          background: {
            url: "/healthprograms/covid.jpg",
            aspectRatio: 1.5,
            whenSmall: {
              totalWidthFactor: 0.75,
              top: -177,
              left: -491
            },
            whenLarge: {
              totalWidthFactor: 1,
              top: -274,
              left: 0,
              useVerticalScreenFactor: true
            },
            whenSingle: {
              width: 740,
              top: -185,
              left: 0
            }
          }
        },
        {
          ref: "tile-lawEnforcement",
          name: "Law Enforcement",
          description: "Here is information about my health program",
          background: {
            url: "/healthprograms/police.jpg",
            aspectRatio: 1.5,
            whenSmall: {
              totalWidthFactor: 1.2,
              top: -403,
              left: -793
            },
            whenLarge: {
              totalWidthFactor: 1,
              top: -214,
              left: 0,
              useVerticalScreenFactor: true
            },
            whenSingle: {
              width: 740,
              top: -183,
              left: 0
            }
          }
        },
        {
          ref: "tile-ptsd",
          name: "PTSD",
          description: "Here is information about my health program",
          background: {
            url: "/healthprograms/ptsd.jpg",
            aspectRatio: 1.5,
            whenSmall: {
              totalWidthFactor: 0.6,
              top: -165,
              left: -319
            },
            whenLarge: {
              totalWidthFactor: 1,
              top: -274,
              left: 0,
              useVerticalScreenFactor: true
            },
            whenSingle: {
              width: 740,
              top: -228,
              left: 0
            }
          }
        }
      ],
      programsTable: null,
      selectedProgram: null,
      previouslySelectedProgram: null,
      selectedTileColumnFactor: 0,
      selectedTileRowFactor: 0,
      collapseTileInfo: null,
      collapsePhotoInfo: null,
      expandAnimation: null
    }
  },
  created() {
    this.updateScreenVar();
    const numRows = Math.ceil(this.programs.length / 3);
    const tilesPerRow = Math.ceil(this.programs.length / numRows);
    this.programsTable = [...Array(numRows).keys()].map((row, rowIndex) => {
      const startIndex = rowIndex * tilesPerRow;
      return {
        row: rowIndex,
        tiles: this.programs.slice(startIndex, startIndex + tilesPerRow)
      }
    });
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    requestAnimationFrame(async () => {
      this.onResize();
    });
    this.highlightedRef = this.programs[0].ref;
    this.startHighlightTimer();
  },
  methods: {
    updateScreenVar() {
      this.isLargeScreen = document.body.clientWidth > 990;
    },
    startHighlightTimer() {
      const programRefs = this.programs.map((program) => program.ref);
      if(this.highlightInterval) {
        clearInterval(this.highlightInterval);
      }
      this.highlightInterval = setInterval(() => {
        const programIndex = programRefs.indexOf(this.highlightedRef);
        const newIndex = programIndex === this.programs.length - 1 ? 0 : programIndex + 1;
        this.highlightedRef = programRefs[newIndex];
      }, 3000);
    },
    mouseEnterTile(programRef) {
      console.log("mouse enter")
      this.highlightedRef = programRef;
      if(this.highlightInterval) {
        clearInterval(this.highlightInterval);
      }
    },
    mouseLeaveTile(programRef) {
      console.log("mouse leave")
      this.startHighlightTimer();
    },
    async selectTile(program, rowFactor, colFactor) {
      this.selectedTileRowFactor = rowFactor;
      this.selectedTileColumnFactor = colFactor;
      this.selectedProgram = program;

      requestAnimationFrame(async () => {
        this.prepExpandAnimation();
        await this.doExpandAnimation();
      });
    },
    async unselectTile() {
      this.previouslySelectedProgram = this.selectedProgram;
      await this.undoExpandAnimation()
      if(this.isLargeScreen) {
        setTimeout(() => {
          this.selectedProgram = null;
        }, 550);
      } else {
        this.selectedProgram = null;
      }
    },
    onResize() {
      this.selectedProgram = null;
      setTimeout(() => {
        this.sizeTilePhotos();
      }, 100);
    },
    sizeTilePhotos() {
      if(!this.$refs["tilesContainer"]) {
        return;
      }

      this.updateScreenVar();
      const containerWidth = this.$refs["tilesContainer"].clientWidth;
      const screenFactor = this.getCurrentScreenSizeFactor();
      for(const program of this.programs) {
        const imageElementId = program.ref + "-image"
        const imageElement = document.getElementById(imageElementId);
        const imageContainerId = program.ref + "-image-container";
        const imageContainer = document.getElementById(imageContainerId);
        imageElement.style.background = `url(${program.background.url})`;
        imageElement.style.backgroundSize = `100% 100%`;

        if(this.isLargeScreen) {
          const photoSizeInfo = {
            width: `${(containerWidth * program.background.whenSmall.totalWidthFactor + (screenFactor / 1.25))}px`,
            height: `${(containerWidth * program.background.whenSmall.totalWidthFactor + (screenFactor / 1.25)) / program.background.aspectRatio}px`,
            marginTop: `${program.background.whenSmall.top + (screenFactor / 2)}px`,
            marginLeft: `${program.background.whenSmall.left + screenFactor}px`
          };
          imageContainer.style.width = photoSizeInfo.width;
          imageContainer.style.height = photoSizeInfo.height;
          imageContainer.style.marginTop = photoSizeInfo.marginTop;
          imageContainer.style.marginLeft = photoSizeInfo.marginLeft;
        } else {
          const screenCalculatedLeft = containerWidth - ((containerWidth / program.background.whenSingle.width) * containerWidth) + program.background.whenSingle.left
          const photoSizeInfo = {
            width: `${program.background.whenSingle.width}px`,
            height: `${program.background.whenSingle.width / program.background.aspectRatio}px`,
            marginLeft: `${-1 * screenCalculatedLeft}px`,
            marginTop: `${program.background.whenSingle.top}px`
          };
          imageContainer.style.width = photoSizeInfo.width;
          imageContainer.style.height = photoSizeInfo.height;
          imageContainer.style.marginTop = photoSizeInfo.marginTop;
          imageContainer.style.marginLeft = photoSizeInfo.marginLeft;
        }
      }
    },
    getCurrentScreenSizeFactor() {
      let screenFactor = 0;
      const screenWidth = document.body.clientWidth;
      if(screenWidth < 1200) {
        screenFactor = 75;
      }
      if(screenWidth < 991) {
        screenFactor = 150;
      }
      return screenFactor;
    },
    prepExpandAnimation() {
      const screenFactor = this.getCurrentScreenSizeFactor();
      const tileRef = this.$refs[this.selectedProgram.ref][0];
      const imageElement = document.getElementById(this.selectedProgram.ref + "-image");
      const marginLeft = getComputedStyle(this.$refs["tilesContainer"]).marginLeft;
      const width = tileRef.clientWidth;
      const height = tileRef.clientHeight;
      const x = width * this.selectedTileColumnFactor;
      const y = height * this.selectedTileRowFactor;
      this.collapseTileInfo = {width, height, marginLeft, x, y};
      this.$refs["largePhotoContainer"].style.width = `${this.collapseTileInfo.width}px`;
      this.$refs["largePhotoContainer"].style.height = `${this.collapseTileInfo.height}px`;
      this.$refs["largePhotoContainer"].style.marginLeft = this.collapseTileInfo.marginLeft;
      this.$refs["largePhotoContainer"].style.transform = `translate(${x}px, ${y}px)`;

      const containerWidth = this.$refs["tilesContainer"].clientWidth;
      const verticalScreenFactor = this.selectedProgram.background.whenLarge.useVerticalScreenFactor ? (screenFactor / 2) : 0;
      const collapsePhotoInfo = {
        width: `${(parseInt(containerWidth) * this.selectedProgram.background.whenSmall.totalWidthFactor)}px`,
        height: `${(parseInt(containerWidth) * this.selectedProgram.background.whenSmall.totalWidthFactor) / this.selectedProgram.background.aspectRatio}px`,
        marginTop: `${this.selectedProgram.background.whenSmall.top + verticalScreenFactor}px`,
        marginLeft: `${this.selectedProgram.background.whenSmall.left + screenFactor + 15}px`
      };
      this.$refs["largePhotoImage"].style.background = `url(${this.selectedProgram.background.url})`;
      this.$refs["largePhotoImage"].style.backgroundSize = `100% 100%`;
      this.$refs["largePhotoImageContainer"].style.width = collapsePhotoInfo.width;
      this.$refs["largePhotoImageContainer"].style.height = collapsePhotoInfo.height;
      this.$refs["largePhotoImageContainer"].style.marginTop = collapsePhotoInfo.marginTop;
      this.$refs["largePhotoImageContainer"].style.marginLeft = collapsePhotoInfo.marginLeft;

      this.$refs["carouselContent"].style.opacity = 0;
    },
    async doExpandAnimation() {
      const programRefs = this.programs.map((program) => program.ref);
      const programIndex = programRefs.indexOf(this.selectedProgram.ref);
      const verticalScreenFactor = this.selectedProgram.background.whenLarge.useVerticalScreenFactor ? this.getCurrentScreenSizeFactor() : 0;
      const width = getComputedStyle(this.$refs["tilesContainer"]).width;
      const height = getComputedStyle(this.$refs["tilesContainer"]).height;
      const props = {
        width: `${parseInt(width) * this.selectedProgram.background.whenLarge.totalWidthFactor}px`,
        height: `${(parseInt(width) * this.selectedProgram.background.whenLarge.totalWidthFactor) / this.selectedProgram.background.aspectRatio}px`,
        marginTop: `${this.selectedProgram.background.whenLarge.top}px`,
        marginLeft: `${this.selectedProgram.background.whenLarge.left}px`
      }
      anime({
        targets: '.large-photo-image-container',
        width: `${parseInt(width) * this.selectedProgram.background.whenLarge.totalWidthFactor}px`,
        height: `${(parseInt(width) * this.selectedProgram.background.whenLarge.totalWidthFactor) / this.selectedProgram.background.aspectRatio}px`,
        marginTop: `${this.selectedProgram.background.whenLarge.top + verticalScreenFactor}px`,
        marginLeft: `${this.selectedProgram.background.whenLarge.left}px`,
        duration: this.isLargeScreen ? 500 : 0,
        easing: 'cubicBezier(.5, .05, .1, .3)'
      });
      const translateY = this.isLargeScreen ? this.collapseTileInfo.y * -1 : -200 - (programIndex * 100);
      await anime({
        targets: '.large-photo-container',
        width,
        height,
        translateX: this.collapseTileInfo.x * -1,
        translateY,
        duration: this.isLargeScreen ? 500 : 0,
        easing: 'cubicBezier(.5, .05, .1, .3)'
      });

      if(!this.isLargeScreen) {
        this.$refs["carouselContent"].style.marginTop = `-400px`;
      } else {
        this.$refs["carouselContent"].style.marginTop = `0px`;
      }
      await anime({
        targets: '.carousel-content',
        opacity: 1,
        translateY: 40,
        easing: 'cubicBezier(.5, .05, .1, .3)',
        duration: 1000
      });

    },
    async undoExpandAnimation(element) {
      const screenFactor = this.getCurrentScreenSizeFactor();
      const containerWidth = this.$refs["tilesContainer"].clientWidth;
      this.$refs["carouselContent"].style.opacity = 0;

      if(this.isLargeScreen) {
        anime({
          targets: '.large-photo-image-container',
          width: `${containerWidth * this.selectedProgram.background.whenSmall.totalWidthFactor}px`,
          height: `${(containerWidth * this.selectedProgram.background.whenSmall.totalWidthFactor) / this.selectedProgram.background.aspectRatio}px`,
          marginTop: this.selectedProgram.background.whenSmall.top + (screenFactor / 2),
          marginLeft: this.selectedProgram.background.whenSmall.left + screenFactor + 15,
          duration: 500,
          easing: 'cubicBezier(.5, .05, .1, .3)'
        });
        anime({
          targets: '.large-photo-container',
          width: this.collapseTileInfo.width,
          height: this.collapseTileInfo.height,
          translateX: 0,
          translateY: 0,
          duration: 500,
          easing: 'cubicBezier(.5, .05, .1, .3)'
        });
      }
    },
  }
}
</script>

<style scoped>

.small-tiles-container {
  width: 100%;
}

.tile {
  height: 200px;
  overflow: hidden;
  background-color: black;
}

.large-tile-column {
  overflow: hidden;
}

.large-photo-container {
  margin-top: -400px;
  overflow: hidden;
}

.large-photo-container .row {
  height: 100%;
}

.large-tile-column {
  padding-left: 0px;
  padding-right: 0px;
}

.large-photo-image {
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.small-photo-image-container {
  opacity: 0.45;
}

.small-photo-image-container:hover {
  opacity: 0.8;
  cursor: pointer;
}

.large-tile-column:hover {
  cursor: pointer;
}

.small-photo-image {
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.small-screen-small-tiles-container .col {
  max-height: 100px;
  padding: 0px;
}

.small-tiles-container.inactive {
  visibility: hidden;
}

.carousel-content {
  text-align: center;
  z-index: -1;
}

.hover-program-name {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: rgba(255,255,255,0.5);
  padding: 7px 10px;
  border: 1px solid white;
  border-top-right-radius: 8px;
  font-size: 24px;
}

.tile.highlighted .small-photo-image-container {
  opacity: 0.9;
}

</style>
