<template>
	<div id="app" ref="app" @scroll="handleScroll()">
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
    <link rel="preconnect" href="https://fonts.gstatic.com"/>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Cinzel:wght@400;500;600;700;800;900&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap"/>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons"/>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"/>
    <router-view :window-scroll-y="windowScrollY" :window-scroll-offset="windowScrollOffset" :window-height="windowHeight">
    </router-view>
	</div>
</template>

<script>

export default {
  data() {
    return {
      windowScrollY: 0,
      windowScrollOffset: 0,
      windowHeight: 0
    }
  },
  methods: {
    handleScroll() {
      this.refreshScrollProps();
    },
    refreshScrollProps() {
      this.windowScrollY = this.$refs["app"].scrollTop;
      this.windowScrollOffset = this.$refs["app"].scrollTop + window.innerHeight;
      this.windowHeight = window.innerHeight;
    }
  },
  mounted() {
    this.refreshScrollProps();
  }
}
</script>

<style scoped>

#app {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

</style>
