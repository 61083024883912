<template>
  <div class="main-section">
    <div class="header-wrapper">
      <div class="header-video">
        <video autoplay muted preload loop>
          <source src="video.mp4">
        </video>
      </div>
    </div>
    <div class="ribbon-anchor">
      <div class="ribbon">
        <div :style="{'--backOfRibbonHeight': backOfRibbonHeight}">
          <div class="ribbon-letter">T</div>
          <div class="ribbon-letter middle-ribbon-letter">H</div>
          <div class="ribbon-letter">F</div>
        </div>
      </div>
    </div>
    <div class="nav-bar">
      <a class="nav-link" @click="goTo('HealthPrograms')">Trauma</a>
      <a class="nav-link" @click="goTo('Providers')">Healthcare</a>
      <a class="nav-link">Fund</a>
      <div class="nav-bar-trim"></div>
    </div>
    <div class="page-content">
      <div class="header-spacing"></div>
      <div class="mission-container">
        <div class="mission-text"><span class="logo-font logo-span">Trauma Healthcare Fund</span> is working on giving all people an equal opportunity to heal following difficult experiences</div>
        <div class="mission-subtext"><strong>Home</strong> is where healing begins. So, the <span class="logo-font">Fund</span> is currently only available to patients in <strong>Boulder County, Colorado</strong>.</div>
      </div>
      <div class="cta">
        <div>
          <a class="href" @click="goToLink('#donate')">DONATE</a>
        </div>
        <div>
          <div class="fa fa-angle-down light link"></div>
        </div>
      </div>
      <div class="get-help-section" ref="getHelpSection">
        <div class="section-title color2">Get Help</div>
        <div class="get-help-photo" v-bind:style="{ opacity: getHelpImageOpacity, backgroundPosition: getHelpImageBGPosition }">
        </div>
        <div class="get-help-photo-overlay" v-bind:style="{ opacity: getHelpImageOverlayOpacity, marginTop: getHelpImageOverlayMarginTop }">
          Eliminating the financial barriers that cripple the process of repairing oneself
        </div>
        <div class="get-help-health-programs transparent-link" v-bind:style="{ opacity: transparentLinkOpacity, right: healthProgramsRight }">
          <div class="transparent-link-title">Health Programs <div class="fa fa-angle-right light link"></div></div>
          <div class="transparent-link-message">Gain access to the <span class="logo-font">Fund</span> once you apply, and are accepted to one of our advertised Health Programs</div>
        </div>
        <div class="get-help-support-center transparent-link" v-bind:style="{ opacity: transparentLinkOpacity, right: supportCenterRight }">
          <div class="transparent-link-title">Support Centers <div class="fa fa-angle-right light link"></div></div>
          <div class="transparent-link-message">Community Support Centers help fit patients into Health Programs and aid in supplying the necessary documentation for applying</div>
        </div>
      </div>
      <div class="give-help-section" ref="giveHelpSection">
        <div class="section-title">Give Help</div>
        <div class="give-help-links-container">
          <div class="give-help-link" v-bind:style="{ opacity: giveHelp1Opacity, marginTop: giveHelp1MarginLeft }">
            <span>Donate</span>
            <div class="donate-link-image"></div>
          </div>
          <div class="give-help-link give-help-link-center" v-bind:style="{ opacity: giveHelp2Opacity, marginTop: giveHelp2MarginTop }">
            <span>Become a Provider</span>
            <div class="become-provider-link-image"></div>
          </div>
          <div class="give-help-link" v-bind:style="{ opacity: giveHelp3Opacity, marginTop: giveHelp3MarginLeft }">
            <span>Become a Support Center</span>
            <div class="become-support-center-link-image"></div>
          </div>
        </div>
      </div>
      <div class="donation-section">
        <div class="section-title color2">Donate</div>
        <div class="color2">Evolving into harmony will require some long, hard looks in the mirror.</div>
        <div class="color2">Your contribution gives the gift of this reflection to those with struggling hearts.</div>
        <DonationForm ref="cardForm"></DonationForm>
        <div class="thank-you color2">Thank you deeply for supporting this cause.</div>
      </div>
    </div>
  </div>
</template>

<script>

import DonationForm from "../components/DonationForm";

export default {
  components: {
    DonationForm
  },
  data() {
    return {
      stripe: null,
      card: null,
      donationFormEmail: null,
      backOfRibbonHeight: '6px',
      getHelpImageOpacity: 1,
      getHelpImageBGPosition: "left top",
      getHelpImageOverlayOpacity: 0,
      getHelpImageOverlayMarginTop: '-20px',
      transparentLinkOpacity: 0,
      supportCenterRight: '9%',
      healthProgramsRight: '16%',
      giveHelp1MarginLeft: '0px',
      giveHelp1Opacity: 0,
      giveHelp2MarginTop: '0px',
      giveHelp2Opacity: 0,
      giveHelp3MarginLeft: '0px',
      giveHelp3Opacity: 0
    }
  },
  props: {
    windowScrollY: {
      type: Number
    },
    windowScrollOffset: {
      type: Number
    },
    windowHeight: {
      type: Number
    }
  },
  methods: {
    goTo(routeName) {
      this.$router.push({
        name: routeName
      });
    },
    handleScroll() {
      if(this.windowScrollY > 0 && this.windowScrollY < 250) {
        const height = 6 + this.windowScrollY;
        this.backOfRibbonHeight = height + 'px';
      } else if (this.windowScrollY > 250) {
        this.backOfRibbonHeight = '256px';
      } else {
        this.backOfRibbonHeight = '6px';
      }

      const getHelpInFrameStart = this.$refs['getHelpSection'].offsetTop;
      const getHelpInFrameEnd = getHelpInFrameStart + this.windowHeight + this.$refs['getHelpSection'].offsetHeight;
      if(this.windowScrollOffset > getHelpInFrameStart && this.windowScrollOffset < getHelpInFrameEnd) {
        const getHelpInFrameHeight = getHelpInFrameEnd - getHelpInFrameStart;
        const pct = (this.windowScrollOffset - getHelpInFrameStart) / getHelpInFrameHeight;
        this.getHelpImageOpacity = Math.max(((-0.8 / 0.5) * pct) + 1, 0.2);
        this.getHelpImageBGPosition = `left ${Math.min(pct * 100, 60)}%`;
        if(pct < 0.25) {
          this.getHelpImageOverlayOpacity = 0;
          this.transparentLinkOpacity = 0;
        } else {
          this.getHelpImageOverlayOpacity = (4 * pct) - 1;
          this.transparentLinkOpacity = (4 * pct) - 1;
        }
        const marginTop = Math.min((140 * pct) - 55, 15)
        this.getHelpImageOverlayMarginTop = `${marginTop}px`;
        this.healthProgramsRight = `${Math.min((12 * pct) + 3, 9)}%`;
        this.supportCenterRight = `${Math.min((20 * pct) + 6, 16)}%`;
      } else {
        this.getHelpImageOpacity = 1;
        this.getHelpImageBGPosition = `left top`;
        this.getHelpImageOverlayOpacity = 0;
        this.getHelpImageOverlayMarginTop = '-20px';
      }

      const giveHelpInFrameStart = this.$refs['giveHelpSection'].offsetTop;
      const giveHelpInFrameEnd = giveHelpInFrameStart + this.windowHeight + this.$refs['giveHelpSection'].offsetHeight;
      if(this.windowScrollOffset > giveHelpInFrameStart && this.windowScrollOffset < giveHelpInFrameEnd) {
        const giveHelpInFrameHeight = giveHelpInFrameEnd - giveHelpInFrameStart;
        const pct = (this.windowScrollOffset - giveHelpInFrameStart) / giveHelpInFrameHeight;
        if(pct < 0.2) {
          this.giveHelp1Opacity = 0;
          this.giveHelp2Opacity = 0;
          this.giveHelp3Opacity = 0;
        } else {
          let outsideLinksMarginTop = Math.max(((-40 / 0.3) * pct) + 66.6666666, 0);
          let insideLinksMarginTop = outsideLinksMarginTop * -1;
          if(document.body.clientWidth < 870) {
            outsideLinksMarginTop = 0;
            insideLinksMarginTop = 0;
          }
          this.giveHelp1Opacity = (4 * pct) - 1;
          this.giveHelp1MarginLeft = `${outsideLinksMarginTop}px`;
          this.giveHelp2Opacity = (4 * pct) - 1;
          this.giveHelp2MarginTop = `${insideLinksMarginTop}px`;
          this.giveHelp3Opacity = (4 * pct) - 1;
          this.giveHelp3MarginLeft = `${outsideLinksMarginTop}px`;

        }
      } else {
        this.giveHelp1MarginLeft = '0px';
        this.giveHelp1Opacity = 0;
        this.giveHelp2MarginTop = '0px';
        this.giveHelp2Opacity = 0;
        this.giveHelp3MarginLeft = '0px';
        this.giveHelp3Opacity = 0;
      }
    },
  },
  watch: {
    windowScrollY(val) {
      this.handleScroll();
    },
    windowScrollOffset(val) {
      this.handleScroll();
    },
    windowHeight(val) {
      this.handleScroll();
    }
  },
  mounted() {
    this.handleScroll();
  }
}
</script>

<style scoped>
  @import '../style/global.css';

  .main-section {
    /*font-family: 'Lora', serif;*/
    font-family: 'Roboto Slab', serif;
    color: var(--main-color1);
  }

  .page-content {
    text-align: center;
  }

  .href:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .header-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    max-height: 600px;
    position: fixed;
    z-index: -1;
  }

  .header-video {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  .header-video > video {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    object-position: center center !important;
    object-fit: cover !important;
  }

  .ribbon-anchor {
    height: 60px;
    width: 100%;
    position: absolute;
    top: 10px;
  }

  .nav-bar {
    height: 60px;
    width: 100%;
    background-color: var(--main-color1);
    text-align: center;
    position: fixed;
    top: 10px;
    z-index: 1;
    filter: drop-shadow(0px 17px 20px black);
  }

  .ribbon {
    position: absolute;
    top: -6.1px;
    left: 10%;
    z-index: 2;
  }

  .ribbon:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 1px solid #939393;
    border-right: 90px solid transparent;
    border-top: 21px solid var(--main-color1);
  }

  .ribbon > div {
    position: relative;
    display: block;
    text-align: center;
    background: var(--main-color1);
    font-size: 14px;
    line-height: 1;
    padding: 12px 8px 10px;
    border-top-right-radius: 8px;
    width: 90px;
    border-top: 1px solid #939393;
    border-right: 1px solid #939393;
    border-left: 1px solid #939393;
  }

  .ribbon > div:before, .ribbon > div:after {
    position: absolute;
    content: "";
  }

  .ribbon > div:before {
    height: 6px;
    width: 6px;
    left: -6px;
    top: 0;
    background: var(--main-color1);
  }

  .ribbon > div:after {
    height: var(--backOfRibbonHeight);
    width: 8px;
    left: -8px;
    top: 0;
    border-radius: 8px 8px 0 0;
    background: #000000;
  }

  .nav-bar-trim {
    position: absolute;
    width: 100%;
    height: 50px;
    border-top: 1px dashed var(--main-color2);
    border-bottom: 1px dashed var(--main-color2);
    vertical-align: middle;
    top: 5px;
    pointer-events:none;
  }

  .nav-bar a {
    display: inline-block;
  }

  .logo-font {
    font-family: 'Cinzel', cursive;
  }

  .logo-span {
    font-size: 30px;
  }

  .nav-link, .nav-link:hover {
    color: var(--main-color2);
    font-family: 'Cinzel', cursive;
    font-size: 30px;
    z-index: 1;
  }

  .ribbon-letter {
    color: var(--main-color2);
    font-family: 'Cinzel', cursive;
    font-size: 85px;
    text-align: center;
  }

  .middle-ribbon-letter {
    margin-top: -10px;
    margin-bottom: -6px;
    font-size: 78px;
    margin-left: 3px;
  }

  .header-spacing {
    height: 600px;
  }

  .mission-container:before {
    background-color: var(--main-color2);
  }

  .mission-container {
    padding-top: 60px;
    text-align: center;
    padding-bottom: 36px;
    width: 100%;
    background-color: var(--main-color2);
  }

  .cta {
    padding-bottom: 40px;
    text-align: center;
    background-color: var(--main-color2);
  }

  .mission-text {
    font-size: 30px;
    font-weight: 400;
    max-width: 977px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .mission-subtext {
    margin-top: 20px;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }

  .give-help-section {
    background-color: var(--main-color2);
    padding-bottom: 50px;
  }

  .give-help-links-container {
    margin-top: 25px;
    text-align: center;
  }

  .give-help-link {
    display: inline-block;
    width: 240px;
    height: 275px;
    border: 2px solid var(--main-color1);
    margin-left: 25px;
    margin-right: 25px;
    position: relative;
    border-radius: 10px;
  }

  .give-help-link:hover {
    cursor: pointer;
  }

  .give-help-link > span {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    font-size: 30px;
    padding: 10px;
  }

  .give-help-link-center > span {
    bottom: 0px;
    top: inherit;
    border-top: 1px solid var(--main-color2);
    background: rgba(255, 255, 255, 0.5);
  }

  .donate-link-image {
    background-image: url(/fund-growth.jpg);
    background-repeat: no-repeat;
    background-size: 320px;
    width: 100%;
    height: 100%;
    background-position: bottom;
    border-radius: 10px;
  }

  .become-provider-link-image {
    background-image: url(/psychologist.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: -40px;
    border-radius: 10px;
  }

  .become-support-center-link-image {
    background-image: url(/this-way-out.png);
    background-repeat: no-repeat;
    background-size: 170px;
    width: 100%;
    height: 100%;
    background-position: bottom;
  }

  .donation-section {
    background-color: var(--main-color1);
    height: 600px;
  }

  .nav-link:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .section-title {
    font-size: 40px;
    padding: 20px;
    font-family: 'Roboto Slab', cursive;
  }

  .get-help-section {
    background-color: var(--main-color1);
    width: 100%;
    padding-bottom: 75px;
    position: relative;
  }

  .get-help-photo {
    background-image: url(/transaction.jpg);
    background-repeat: no-repeat;
    height: 290px;
    background-size: 600px;
    margin-left: 50px;
    padding-bottom: 75px;
  }

  @media screen and (max-width: 675px){
    .get-help-photo {
      width: 100%;
      background-size: 100%;
    }
  }

  .get-help-photo-overlay {
    position: absolute;
    width: 350px;
    color: var(--main-color2);
    font-size: 21px;
    top: 116px;
    left: 30px;
    font-weight: 300;
    margin-left: 50px;
  }

  @media screen and (max-width: 1023px){
    .get-help-photo-overlay {
      max-width: 220px;
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 633px){
    .get-help-photo-overlay {
      position: initial;
      max-width: 400px;
      margin-bottom: 25px;
      margin-top: 30px !important;
    }
  }

  .get-help-health-programs {
    position: absolute;
    right: 9%;
    top: 135px;
    border: 1px solid var(--main-color2);
    color: var(--main-color2);
    background: rgba(255,255,255,0.5);
  }

  .get-help-support-center {
    position: absolute;
    right: 16%;
    top: 266px;
    border: 1px solid var(--main-color2);
    color: var(--main-color2);
    background: rgba(255,255,255,0.5);
  }

  .transparent-link {
    max-width: 500px;
  }

  @media screen and (max-width: 850px){
    .transparent-link {
      max-width: 300px;
    }
  }

  .transparent-link:hover {
    cursor: pointer;
    background-color: rgba(255,255,255, 0.6);
  }

  .transparent-link-title {
    text-align: left;
    border-bottom: 1px solid var(--main-color2);
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .transparent-link-message {
    font-size: 14px;
    padding: 10px;
    text-align: left;
  }

</style>
