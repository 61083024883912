<template>
  <b-form class="donation-form-container">
    <b-input-group>
      <b-input-group-prepend is-text>
        <div class="fa fa-at field-icon"></div>
      </b-input-group-prepend>
      <b-form-input placeholder="Email Address" v-model="email"></b-form-input>
    </b-input-group>
    <div class="amount-container">
      <div class="amount-label">Contribution Amount</div>
      <div class="amount-button-row">
        <div v-bind:class="[{'selected': selectedAmountBtn === '25'}, 'amount-btn']" @click="selectAmount('25')"><span>$25</span></div>
        <div v-bind:class="[{'selected': selectedAmountBtn === '50'}, 'amount-btn']" @click="selectAmount('50')"><span>$50</span></div>
        <div v-bind:class="[{'selected': selectedAmountBtn === '100'}, 'amount-btn']" @click="selectAmount('100')"><span>$100</span></div>
        <div v-bind:class="[{'selected': selectedAmountBtn === '250'}, 'amount-btn']" @click="selectAmount('250')"><span>$250</span></div>
        <div class="custom-amount-container" v-if="selectedAmountBtn === 'custom'">
          <div class="custom-amount-label">Custom Amount</div>
          <b-input-group>
            <b-input-group-prepend is-text>
              <div class="fa fa-dollar-sign field-icon"></div>
            </b-input-group-prepend>
            <b-form-input ref="customAmount" v-model="amount" type="number" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"></b-form-input>
          </b-input-group>
        </div>
        <div v-else class="amount-btn" @click="selectAmount('custom')"><span>Custom</span></div>
      </div>
    </div>
    <div id="stripeCard" class="card" ref="card"></div>
    <div class="error" v-if="error">{{error}}</div>
    <div class="success" v-if="success">{{success}}</div>
    <b-button v-bind:class="[{'disabled': !isFormValid()}, 'submit-btn']" @click="donateClicked()">Donate<span v-if="amount"> {{formatWholeCurrency(amount)}} </span></b-button>
    <div class="vertical-trim"></div>
  </b-form>
</template>

<script>
import Vue from 'vue'

export default {
  name: "DonationForm",
  data() {
    return {
      email: null,
      stripePubKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      stripeInstance: null,
      stripeElementsInstance: null,
      cardElement: null,
      selectedAmountBtn: null,
      amount: null,
      isStripeElementValid: false,
      error: null,
      success: null,
      paymentIntent: null
    }
  },
  methods: {
    selectAmount(btnId) {
      this.selectedAmountBtn = btnId;
      this.amount = btnId === 'custom' ? null : parseInt(btnId);
      if(btnId === "custom") {
        Vue.nextTick(() => {
          this.$refs.customAmount.$el.focus();
        });
      }
    },
    formatWholeCurrency(amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      });
      return formatter.format(amount);
    },
    isFormValid() {
      return this.amount > 0
          && this.isValidEmail(this.email)
          && this.isStripeElementValid
          && (this.paymentIntent && this.paymentIntent.amount === this.amount * 100);
    },
    isValidEmail(email) {
      let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return email && re.test(String(email).toLowerCase());
    },
    onStripeElementChanged(event) {
      this.isStripeElementValid = event.complete === true;
    },
    async donateClicked() {
      if(this.isFormValid()) {
        await this.confirmPayment();
      }
    },
    async fetchPaymentIntent() {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/generatePaymentIntent`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'POST'
        },
        body: JSON.stringify({amount: this.amount})
      });
      const responseJson = await response.json();
      if(response.ok) {
        return responseJson;
      } else {
        throw "Error creating payment intent";
      }
    },
    async updatePaymentIntent(bodyObj) {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/updatePaymentIntent`, {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'POST'
        },
        body: JSON.stringify(bodyObj)
      });
      const responseJson = await response.json();
      if(response.ok) {
        return responseJson;
      } else {
        throw "Error updating payment intent";
      }
    },
    async updatePaymentIntentAmount() {
      return await this.updatePaymentIntent({paymentIntentId: this.paymentIntent.id, amount: this.amount});
    },
    async updatePaymentIntentReceiptEmail() {
      return await this.updatePaymentIntent({paymentIntentId: this.paymentIntent.id, email: this.email});
    },
    async confirmPayment() {
      try {
        await this.stripeInstance.confirmCardPayment(this.paymentIntent.client_secret, {
          payment_method: {
            card: this.cardElement,
            billing_details: {
              email: this.email,
            },
          }
        });
        await this.updatePaymentIntentReceiptEmail();
        this.clearForm();
        this.success = `The payment for ${this.formatWholeCurrency(this.amount)} was successful.  Thank you!`
        setTimeout(() => {
          this.success = null;
        }, 10000);
      } catch (err) {
        this.error = "There was a problem processing your payment.  Please try again"
      }
    },
    mountStripeElement() {
      this.stripeElementsInstance = this.stripeInstance.elements({
        fonts: [{
          cssSrc: 'https://fonts.googleapis.com/css?family=Roboto+Slab',
        }],
      });
      this.cardElement = this.stripeElementsInstance.create('card', {
        iconStyle: 'solid',
        style: {
          base: {
            iconColor: '#8d8d8d',
            color: '#16171c',
            fontWeight: '500',
            fontFamily: 'Roboto Slab, serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
              color: '#16171c',
            },
            '::placeholder': {
              color: '#8d8d8d',
            },
          },
          invalid: {
            iconColor: '#bb1818',
            color: '#bb1818',
          },
        },
      });
      Vue.nextTick(() => {
        this.cardElement.mount(this.$refs["card"]);
        this.stripeElementsInstance.getElement("card").on('change', this.onStripeElementChanged);
      });
    },
    clearForm() {
      this.email = null;
      this.amount = null;
      this.selectedAmountBtn = null;
      this.paymentIntent = null;
      this.cardElement.clear();
      this.isStripeElementValid = false;
    }
  },
  watch: {
    async amount(val) {
      if(val && val > 0) {
        if(this.paymentIntent) {
          await this.updatePaymentIntentAmount();
        } else {
          this.paymentIntent = await this.fetchPaymentIntent();
        }
      }
    }
  },
  mounted() {
    this.stripeInstance = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    this.mountStripeElement();
  }
}
</script>

<style scoped>
@import '../style/global.css';

.card {
  width: 350px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 15px;
  background-color: var(--main-color2);
  border-radius: 7px;
}

/*.email-icon {*/
/*  position: absolute;*/
/*  z-index: 2;*/
/*  color: #8d8d8d;*/
/*  font-size: 20px;*/
/*  padding: 10px;*/
/*  margin-left: -7px;*/
/*  border-top-left-radius: 7px;*/
/*  border-bottom-left-radius: 7px;*/
/*  background: var(--main-color2);*/
/*}*/

.money-icon {
  position: absolute;
  z-index: 2;
  color: #8d8d8d;
  font-size: 20px;
  padding: 10px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background: var(--main-color2);
}

.donation-form-container {
  margin-top: 30px;
  margin-bottom: 14px;
}

.donation-form-container .input-group {
  width: 260px;
  margin: auto;
}

.amount-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.amount-label {
  margin-bottom: 5px;
  color: var(--main-color2);
}

.amount-btn {
  color: var(--main-color2);
  border: 2px solid var(--main-color2);
  border-radius: 5px;
  width: 75px;
  height: 50px;
  display: inline-block;
  position: relative;
  outline: none;
  margin-left: 3px;
  margin-right: 3px;
}

.amount-btn.selected {
  background-color: var(--main-color2);
  color: var(--main-color1);
}

.amount-btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.amount-btn:hover {
  cursor: pointer;
  background-color: var(--main-color2);
  color: var(--main-color1);
}

.custom-amount-container {
  display: inline-block;
  top: -15px;
  position: relative;
}

.custom-amount-container .input-group {
  width: 100px;
}

.custom-amount-label {
  color: var(--main-color2);
}

.vertical-trim {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 1px;
  height: 40px;
  background: var(--main-color2);
}

.error {
  color: red;
  margin-bottom: 10px;
}

.success {
  color: green;
  margin-bottom: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>
