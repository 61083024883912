import Vue from 'vue';
import Router from 'vue-router';
import App from './App.vue';
import Index from './pages/Index.vue';
import Providers from './pages/Providers.vue';
import HealthPrograms from './pages/HealthPrograms.vue';
import HowItWorks from './pages/HowItWorks.vue';
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(Router);
Vue.config.productionTip = false;

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index,
            props: true
        },
        {
            path: '/providers',
            name: 'Providers',
            component: Providers,
            props: true
        },
        {
            path: '/health-programs',
            name: 'HealthPrograms',
            component: HealthPrograms,
            props: true
        },
        {
            path: '/how-it-works',
            name: 'HowItWorks',
            component: HowItWorks,
            props: true
        }
    ]
});

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
