<template>
  <div class="health-programs-page">
    <div class="logo-title">
      <div class="logo-back" @click="goHome()">
        <div class="fa fa-angle-left left-caret"></div>
        <div class="thf-logo"></div>
      </div>
      <div class="title-text">Health Programs</div>
    </div>
    <div class="description-container">
      <div class="description description1">Patients can only receive membership access to the <span class="logo-font">THF</span> Provider Network by being accepted or renewing an application to a Health Program from this page.  After a short verification process, being accepted secures their spot in line, and the membership will be turned on in the app as soon as enough funds are available.</div>
      <div class="description description2">Each Health Program membership is variable in duration and amount, depending on the supply, and demand of the health market and the available capital in the <span class="logo-font">Fund</span>.  Once memebrship begins, patients are automatically put in line for a membership renewals, or "re-draw".  Renewals continue to get awarded along side new memberships where appropriate.</div>
      <div class="description description3"><strong>Note:</strong> The <span class="logo-font">THF</span> Provider Network is currently only available in <strong>Boulder County, Colorado</strong>.  Please check back soon if you live too far from this area.  We are expanding this initiative further as quickly as our bandwith allows.</div>
    </div>
    <div class="carousel-container">
      <div class="section-title">Current Programs</div>
      <HealthProgramCarousel/>
    </div>
  </div>
</template>

<script>
import HealthProgramCarousel from "@/components/HealthProgramCarousel";
export default {
  name: "HealthPrograms",
  components: {HealthProgramCarousel},
  methods: {
    goHome() {
      this.$router.push({
        name: 'Index'
      })
    }
  }
}
</script>

<style scoped>

.health-programs-page {
  color: var(--main-color1);
  font-family: "Roboto Slab", serif;
}

.logo-title {
  width: 100%;
}

.logo-title > div {
  display: inline-block;
}

.left-caret {
  font-size: 60px;
  vertical-align: top;
  margin-top: 55px;
}

.thf-logo {
  background: url("/thf-logo.png");
  background-repeat: no-repeat;
  background-size: 180px;
  width: 180px;
  height: 180px;
  margin-left: 10px;
}

.logo-back {
  margin-left: 80px;
}

.logo-back > div {
  display: inline-block;
}

.logo-back:hover {
  cursor: pointer;
}

.title-text {
  font-size: 60px;
  margin-top: 50px;
  margin-left: 40px;
  vertical-align: top;
}

.logo-font {
  font-family: "Cinzel", serif;
}

.description {
  margin-left: 7%;
  max-width: 800px;
  text-align: left;
  padding-right: 50px;
}

.description1 {
  margin-bottom: 15px;
}

.description2, .description3 {
  margin-top: 30px;
}

.description3 {
  margin-bottom: 65px;
}

.carousel-container {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 550px;
}

.section-title {
  font-size: 30px;
  padding-bottom: 20px;
  font-family: 'Roboto Slab', cursive;
  text-align: center;
}

</style>
