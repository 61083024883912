<template>
  <div class="provider-network-page flex-wrapper">
    <div>
      <div class="logo-title">
        <div class="logo-back" @click="goHome()">
          <div class="fa fa-angle-left left-caret"></div>
          <div class="thf-logo"></div>
        </div>
        <div class="title-text">Provider Network</div>
      </div>
      <div class="boulder-county-image"></div>
      <div class="description-container">
        <div class="description description1">This group of passionate health and wellness businesses provide the services that are rendered to patients who have memberships with <span class="logo-font">Trauma Healthcare Fund</span>.  Each patient's trauma story is completely unique, thus any types of healing services are offered.  <span class="logo-font">THF</span>'s Provider Network gives autonomy to patients to choose when each service is right for them from a balance of holistic and allopathic facilities. Some examples include:</div>
        <div class="examples-list">
          <ul>
            <li>Hospitals</li>
            <li>Doctors' Offices</li>
            <li>Blood Work Laboratories</li>
            <li>Rehab Centers</li>
            <li>Psychotherapists / Counsellors</li>
            <li>Accupressure / Accupuncture</li>
          </ul>
          <ul>
            <li>Fitness Centers</li>
            <li>Float Therapy</li>
            <li>Yoga</li>
            <li>Martial Arts</li>
            <li>Intravenous Fluids</li>
            <li>Body Workers</li>
          </ul>
        </div>
<!--        TODO FAQ section near provider form -->
<!--          Rather than losing business income in exchange for exposure with "deal sites", opting in as a <span class="logo-font">THF</span> Provider is a handy way of gaining more full-paying clients, promoting the power of local business, and giving back to your own community all at once.</div>-->
        <div class="description description2">Being a provider in the <span class="logo-font">THF</span> Network is easy.  Once verified and accepted, you only need an iOS device to scan the "service tickets" that patients will bring to spend at your facility.  Transactions made through the app transfer funds directly to your business bank account within one business day.  If you wish, you may wait to configure your banking details until after your first transaction is processed.</div>
        <div class="description description3"><strong>Note:</strong> Currently, you must be a health or wellness business near <strong>Boulder County, Colorado</strong> to join the network.  Please check back soon if you are more than 25 miles outside of this area.  We are expanding this initiative further as quickly as our bandwith allows.</div>
      </div>
      <div class="become-container">
        <div class="section-title">Become a <span class="logo-font">THF</span> Provider</div>
        <div class="provider-inquiry-form">
          <b-form>
            <div class="form-left-side">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <div class="fa fa-hospital field-icon"></div>
                </b-input-group-prepend>
                <b-form-input placeholder="Facility Name"></b-form-input>
              </b-input-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <div class="fa fa-phone field-icon"></div>
                </b-input-group-prepend>
                <b-form-input placeholder="Facility Phone"></b-form-input>
              </b-input-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <div class="fa fa-globe-americas field-icon"></div>
                </b-input-group-prepend>
                <b-form-input placeholder="Facility Website"></b-form-input>
              </b-input-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <div class="fa fa-user-tie field-icon"></div>
                </b-input-group-prepend>
                <b-form-input placeholder="Facility Owner (full name)"></b-form-input>
              </b-input-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <div class="fa fa-at field-icon"></div>
                </b-input-group-prepend>
                <b-form-input placeholder="Facility Owner Email"></b-form-input>
              </b-input-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <div class="fa fa-mobile-alt field-icon"></div>
                </b-input-group-prepend>
                <b-form-input placeholder="Facility Owner Phone"></b-form-input>
              </b-input-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <div class="fa fa-user field-icon"></div>
                </b-input-group-prepend>
                <b-form-input placeholder="Person making this inquiry"></b-form-input>
              </b-input-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <div class="fa fa-hourglass-start field-icon"></div>
                </b-input-group-prepend>
                <b-form-input placeholder="Year that business began"></b-form-input>
              </b-input-group>
            </div>
            <div class="form-right-side">
              <b-input-group>
                <b-form-input placeholder="Choose a" v-model="selectedType" readonly></b-form-input>
                <template #append>
                  <b-dropdown text="Business Type" v-model="selectedType">
                    <b-dropdown-item v-for="option in businessTypeOptions" :key="option" @click="selectedType = option">{{option}}</b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-input-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <div class="fa fa-quote-right field-icon"></div>
                </b-input-group-prepend>
                <b-form-input placeholder="Describe your services in one sentence"></b-form-input>
              </b-input-group>
              <b-input-group>
                <b-form-textarea
                    placeholder="Additional Details. Why do you most want to join this network?"
                ></b-form-textarea>
              </b-input-group>
            </div>
          </b-form>
          <b-button v-bind:class="[{'disabled': !isFormValid()}, 'submit-btn']">Submit Inquiry</b-button>
        </div>
      </div>
      <div class="browse-container">
        <div class="section-title">Not a Provider?</div>
        <div class="section-subtitle">You can still Support</div>
        <div class="support-center-content">
          <div class="help-image"></div>
          <div class="support-center-description">Support Centers around the community partner with <span class="logo-font">THF</span> to help their neighbors expedite admission into our advertised Health Programs. Support Centers are businesses, non-profits, individuals, community leaders, spiritual leaders, etc. who all have a direct line of communication with <span class="logo-font">THF</span> management team.</div>
          <div class="support-center-description">As of now, anyone who is closely connected to the community of <strong>Boulder County, Colorado</strong> is welcome to volunteer their help in this effort.  Right now, we are training Support Centers how to help with an aggressive campaign for admissions starting in Q2 of 2022.  To inquire about being a Support Center for Boulder County Colorado and help with this process, please email <a href="mailto: support@traumahealthcarefund.org">support@traumahealthcarefund.org</a></div>
          <div class="spacer"></div>
        </div>
      </div>
    </div>
    <div class="footer">

    </div>
  </div>
</template>

<script>
export default {
  name: "Providers",
  data() {
    return {
      providerForm: {},
      selectedType: null,
      businessTypeOptions: [
        "Hospital",
        "Doctors Office",
        "Blood Work Laboratory",
        "Mental Health Group / Rehab Center",
        "Pharmacy",
        "Physical Therapy",
        "Psychotherapists / Counsellors",
        "Acupressure / Acupuncture",
        "Chiropractor",
        "Massage Therapy",
        "Fitness Center",
        "Float Therapy",
        "Yoga",
        "Martial Arts",
        "IV Fluids",
        "Body Work / Energy Healing",
        "Nutritionist",
        "Other"
      ]
    };
  },
  methods: {
    goHome() {
      this.$router.push({
        name: 'Index'
      })
    },
    isFormValid() {
      return true;
    }
  }
}
</script>

<style scoped>

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.provider-network-page {
  color: var(--main-color1);
  font-family: "Roboto Slab", serif;
}

.logo-title {
  width: 100%;
}

.logo-title > div {
  display: inline-block;
}

.left-caret {
  font-size: 60px;
  vertical-align: top;
  margin-top: 55px;
}

.thf-logo {
  background: url("/thf-logo.png");
  background-repeat: no-repeat;
  background-size: 180px;
  width: 180px;
  height: 180px;
  margin-left: 10px;
}

.logo-back {
  margin-left: 80px;
}

.logo-back > div {
  display: inline-block;
}

.logo-back:hover {
  cursor: pointer;
}

.title-text {
  font-size: 60px;
  margin-top: 50px;
  margin-left: 40px;
  vertical-align: top;
}

.logo-font {
  font-family: "Cinzel", serif;
}

.description-container {

}

.description {
  margin-left: 7%;
  max-width: 800px;
  text-align: left;
  padding-right: 50px;
}

.description1 {
  margin-bottom: 15px;
}

.description2, .description3 {
  margin-top: 30px;
}

.description3 {
  margin-bottom: 65px;
}

.become-container {
  color: white;
  background-color: var(--main-color1);
  width: 100%;
}

.section-title {
  font-size: 30px;
  text-align: center;
  padding: 16px;
}

.section-subtitle {
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.logo-container {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 130px;
}

.help-image {
  background-image: url(/this-way-out.png);
  background-repeat: no-repeat;
  background-size: 170px;
  width: 157px;
  height: 130px;
  background-position: bottom;
  float: left;
  margin-right: 23px;
}

.boulder-county-image {
  background-image: url(/boulder-county.png);
  background-repeat: no-repeat;
  background-size: 375px;
  width: 375px;
  height: 297px;
  background-position: bottom;
  margin-right: 10%;
  margin-bottom: 20px;
  top: 163px;
  z-index: -1;
  opacity: 0.75;
  float: right;
  margin-left: 10px;
  transform: rotate(-3deg);
}

.examples-list {
  margin-left: 80px;
}

.examples-list ul {
  display: inline-block;
}

.provider-inquiry-form {
  text-align: center;
  padding-bottom: 25px;
}

.form-left-side {
  display: inline-block;
}

.form-right-side {
  display: inline-block;
  margin-left: 25px;
  vertical-align: bottom;
}

.input-group {
  width: 280px;
  margin: auto;
  margin-bottom: 10px;
}

.input-group textarea {
  height: 280px;
}

.form-right-side .input-group {
  width: 370px;
}

.submit-btn {
  margin-top: 13px;
}

.support-center-content {
  margin-left: 50%;
  transform: translateX(-50%);
}

.support-center-description {
  text-align: justify;
  max-width: 660px;
  margin-top: 20px;
}

.support-center-description a {
  color: var(--main-color1);
  font-weight: bold;
}

.spacer {
  height: 75px;
}

.footer {
  width: 100%;
  height: 100px;
  background-color: var(--main-color1);
}

@media screen and (max-width: 993px){
  .boulder-county-image {
    margin-top: 220px;
  }
}

@media screen and (max-width: 820px){
  .boulder-county-image {
    margin-top: 34px;
  }
}

</style>
